import { getConnectionProviderTypes } from "./connectionsApi";
import { ProviderType } from "../../components/common/ObjectTypes";
import { page_size } from "../apiUtils";

const loadProviderTypes = async (
    keycloak_token: string,
    page_offset: number,
    providerTypesSearchInput: any,
    providerTypes: ProviderType[],
): Promise<[ProviderType[], number, Error | null]> => {
    let total_provider_types = 0;
    try {
        const query = {
            offset: page_offset,
            search: providerTypesSearchInput,
            search_fields: "name,value",
            ordering: 'name',
            previous: process.env.REACT_APP_BASE_URL_LOCAL + "/integrations/urls/?is_provider=true&pager=limit&limit=" + page_size + "&offset=" + (page_offset - page_size),
            next: process.env.REACT_APP_BASE_URL_LOCAL + "/integrations/urls/?is_provider=truel&pager=limit&limit=" + page_size + "&offset=" + (page_offset + page_size)
        }
        const temp = await getConnectionProviderTypes(keycloak_token, query);
        const new_array: ProviderType[] = temp.results
        const duplicates_removed: ProviderType[] = Array.from(new Set(new_array.map(obj => obj.id))).map(id => {
            return new_array.find(obj => obj.id === id)!;
        });
        const alphabetized = duplicates_removed.sort((a, b) => a.name.localeCompare(b.name));
        total_provider_types = temp.count;
        return [alphabetized, total_provider_types, null]
    } catch (err: any) {
        console.log(err);
        return [[], 0, err];
    }
}

export default loadProviderTypes;